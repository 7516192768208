import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ShowCard from "../components/show-card"
import { graphql } from "gatsby"

const ShowsPage = ({ data }) => (
  <Layout>
    <Seo title="Shows" />
    <div className="text-4xl font-black mb-10 full-width px-10 md:px-20 lg:px-24 xl:px-40 py-10 bg-hctv-navy flex">
          <h2 className="hctv_underline text-white">Shows</h2>
        </div>
    <div className="grid justify-items-stretch grid-cols-2 md:grid-cols-3 gap-6">
    {data.allVimeoAlbum.nodes.map((video) => (
    <ShowCard title={video.title} thumbnail={video.thumbnail} link={video.id}/>
    ))}
    </div>
  </Layout>
)

export const query = graphql`
  {
    allVimeoAlbum {
      nodes {
        id
        thumbnail
        title
      }
    }
  }
`

export default ShowsPage
