import * as React from "react"
import { Link } from "gatsby"

function ShowCard(props) {
  return (
    <Link to={props.link}>
    <div className="aspect bg-cover bg-center flex flex-col group shadow transition-shadow group-hover:shadow-dark" style={{background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${props.thumbnail})`}}>
      <div className="flex justify-center items-center mt-2 w-full h-48">
        <Link to={props.link} className="text-lg text-white text-center font-black leading-6 p-4">{props.title}</Link>
      </div>
    </div>
    </Link>
  )
}

export default ShowCard
